import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikProps } from "formik";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { sessionProvider } from "../../../blocks/forgot-password/src/ForgotPasswordController.web";

export enum LoginEnum {
  LoginScreen = "Login",
  ResetedScreen = "Password Successfully Reset",
  LoginTokenType = "login",
  Token = "token",
  TokenType = "token_type",
  ResetTokenType = "reseted_password",
  RefreshToken = "refresh_token",
  KeepLoginIn = "keep_me_signed_in"
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  rememberMe: boolean;
  showPassword: boolean;
  screenLabel: string;
  isSnackbarOpen: boolean;
  snackbarMessage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginApiId: string = "";

  loginFormProps?: FormikProps<{
    email: string;
    password: string;
  }> = undefined;

  token: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationEmailLogInMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      rememberMe: false,
      showPassword: false,
      screenLabel: LoginEnum.LoginScreen,
      isSnackbarOpen: false,
      snackbarMessage: ""
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.redirection();
    // Customizable Area End
  }

  // Customizable Area Start
  async redirection() {
    this.token = await StorageProvider.get(LoginEnum.Token);
    const localStorageTokenType = await StorageProvider.get(LoginEnum.TokenType);

    const sessionToken = sessionProvider("get", LoginEnum.Token);
    const sessionTokenType = sessionProvider("get", LoginEnum.TokenType);

    if (!this.token && !sessionToken) {
      return;
    }

    if (localStorageTokenType != LoginEnum.LoginTokenType && sessionTokenType != LoginEnum.LoginTokenType) {

      if (localStorageTokenType == LoginEnum.ResetTokenType || sessionTokenType == LoginEnum.ResetTokenType) {
        this.setState({ screenLabel: LoginEnum.ResetedScreen });
      }

      return;
    }

    this.props.navigation.navigate("ClaimsDashboardFirstUrl");
  }

  login = (values: { email: string; password: string }) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const data = {
      type: "email_account",
      attributes: values,
      keep_me_signed_in: this.state.rememberMe
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleLogin = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (
      responseJson.errors &&
      responseJson.errors[0]?.email &&
      this.loginFormProps
    ) {
      this.loginFormProps.setErrors({
        email: responseJson.errors[0]?.email,
      });

      return;
    }

    if (
      responseJson.errors &&
      responseJson.errors[0]?.password &&
      this.loginFormProps
    ) {
      this.loginFormProps.setErrors({
        password: responseJson.errors[0]?.password,
      });

      return;
    }

    if (
      responseJson.errors &&
      responseJson.errors[0]?.failed_login &&
      this.loginFormProps
    ) {
      this.loginFormProps.setErrors({
        email: responseJson.errors[0]?.failed_login,
      });

      return;
    }

    if (this.state.rememberMe) {
      await StorageProvider.set(LoginEnum.TokenType, "login");
      await StorageProvider.set(LoginEnum.Token, responseJson.meta.token);
      await StorageProvider.set(LoginEnum.RefreshToken, responseJson.meta.refresh_token);
      await StorageProvider.set("id", responseJson.meta.id);
      await StorageProvider.set(LoginEnum.KeepLoginIn, responseJson.meta.keep_me_signed_in);
    } else {
      await sessionProvider("set", LoginEnum.TokenType, "login");
      await sessionProvider("set", LoginEnum.Token, responseJson.meta.token);
      await sessionProvider("set", LoginEnum.RefreshToken, responseJson.meta.refresh_token);
      await sessionProvider("set", "id", responseJson.meta.id);
      await sessionProvider("set", LoginEnum.KeepLoginIn, responseJson.meta.keep_me_signed_in);
    }

    this.props.navigation.navigate("ClaimsDashboardFirstUrl");
  };

  forgotPassword = () => {
    this.props.navigation.navigate("ForgotPassword");
  };

  updateLoginFormProps = (
    props: FormikProps<{
      email: string;
      password: string;
    }>
  ) => {
    this.loginFormProps = props;
  };

  handleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  handleClickRememberMe = () => {
    this.setState({
      rememberMe: !this.state.rememberMe,
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestErrorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestErrorMessage !== undefined) {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: apiRequestErrorMessage,
        loading: false
      })
      return;
    }

    if (apiRequestCallId === this.loginApiId) {
      this.handleLogin(responseJson);
    }
    // Customizable Area End
  }
}
