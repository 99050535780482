import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { sessionProvider } from "../../../blocks/forgot-password/src/ForgotPasswordController.web";
import { Claims, ClaimCategory, requestApi } from "../../../blocks/tasks/src/ClaimsDashboardController.web";
import { FormikProps } from "formik";
import { baseURL } from "../../../blocks/bulkuploading/src/BulkUploadingController";

export const listRequestInfo = [
  "Claim Category",
  "Full Name",
  "Travel Agent",
  "Number of Bags Checked",
  "Credit Card",
  "Departure City",
  "Were Points Used",
  "Connection(s)",
  "Confirmation Number",
  "ID Card",
  "Destination City",
  "Airline",
  "Flight Number",
];

export const defaultClaimValue = {
  id: "",
  type: "",
  attributes: {
    account_id: "",
    pnr: "",
    claim_category_id: "",
    name_of_airline: "",
    number_of_bags_checked: 0,
    full_name: "",
    flight_number: "",
    credit_card: "",
    points_used: "",
    departure_city: "",
    destination_city: "",
    connections: "",
    airline: "",
    claim_status: "",
    confirmation_number: "",
    description_of_luggage_contents: "",
    updated_at: "",
    status: "",
    agreed: "",
    claim_category_name: "",
    claim_date: "",
    damaged_luggage_photos: []
  }
};

export const formInitialValues = {
  request_category: false,
  request_full_name: false,
  request_travel_agent: false,
  request_number_of_bags: false,
  request_credit_card: false,
  request_points_used: false,
  request_departure_city: false,
  request_connection: false,
  request_confirmation_number: false,
  request_id_card: false,
  request_destination_city: false,
  request_airline: false,
  request_flight_number: false,
}

export type FormikClaimFormProps = {
  request_category: boolean;
  request_full_name: boolean;
  request_travel_agent: boolean;
  request_number_of_bags: boolean;
  request_credit_card: boolean;
  request_points_used: boolean;
  request_departure_city: boolean;
  request_connection: boolean;
  request_confirmation_number: boolean;
  request_id_card: boolean;
  request_destination_city: boolean;
  request_airline: boolean;
  request_flight_number: boolean;
}

export interface FormikErrorsType {
  [key: string]: string;
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  route: any;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  token: string;
  errorMessage: string;
  claimData: Claims;
  claimCategories: Array<ClaimCategory>;
  isSnackbarOpen: boolean;
  snackbarMessage: string;
  snackBarType: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ClaimDetailedInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getClaimDetailApiId: string = "";
  getAllClaimCategoriesApiId: string = "";
  updateStatusApiId: string = "";
  requestMoreInfoApiId: string = "";

  claimId = this.props.route.params?.path.claimId;

  claimFormProps?: FormikProps<FormikClaimFormProps> = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      loading: false,
      token: "",
      errorMessage: "",
      claimData: defaultClaimValue,
      claimCategories: [],
      isSnackbarOpen: false,
      snackbarMessage: "",
      snackBarType: "error"
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.redirection();
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate() {
    const { route } = this.props;

    if (route.params?.path?.claimId) {
      this.claimId = route.params.path.claimId;
    }
  }

  async redirection() {
    const token = await StorageProvider.get("token");
    const localSTokenType = await StorageProvider.get("token_type");

    const sessionToken = sessionProvider("get", "token");
    const sessionTokenType = sessionProvider("get", "token_type");

    if ((token && localSTokenType == "login")) {
      this.getClaimDetailed(token, this.claimId ? this.claimId : "");
      this.getAllClaimCategories(token);

      this.setState({ token: token });
      return;
    }

    if ((sessionToken && sessionTokenType == "login")) {
      this.getClaimDetailed(sessionToken, this.claimId ? this.claimId : "");
      this.getAllClaimCategories(sessionToken);

      this.setState({ token: sessionToken });
      return;
    }

    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  getClaimDetailed = (token: string, claimId: string) => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      "token": token
    };

    this.getClaimDetailApiId = requestApi(header, "GET", "bx_block_custom_forms/claims/" + claimId);
  }

  handleApiResponse = async (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson.errors && responseJson.errors[0]) {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: responseJson.errors[0]
      });
      return;
    }

    if (!responseJson.data && responseJson.message) {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: responseJson.message
      });

      return;
    }

    this.setState({ claimData: responseJson.data });
  }

  getAllClaimCategories = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      "token": token
    };

    this.getAllClaimCategoriesApiId = requestApi(header, "GET", "bx_block_custom_forms/claims/fetch_claim_category");
  }

  handleGetAllClaimTypeResponse = async (responseJson: any) => {
    if (responseJson.errors && responseJson.errors[0]) {
      this.setState({
        errorMessage: responseJson.errors[0],
      });
      return;
    }

    this.setState({ claimCategories: responseJson.claim_category });
  }

  updateClaimFormProps = (props: FormikProps<FormikClaimFormProps>) => {
    this.claimFormProps = props;
  };

  formValidate = (values: FormikClaimFormProps) => {
    const errors: FormikErrorsType = {};
    const hasAtLeastOneChecked = Object.values(values).some((val) => val === true);

    if (!hasAtLeastOneChecked) {
      errors['at-least-one-checked'] = 'At least one checkbox must be checked.';

      this.setState({
        isSnackbarOpen: true,
        snackBarType: "error",
        snackbarMessage: 'At least one checkbox must be checked.',
      });
    }

    return errors;
  };

  getListRequested = (requestObj: any) => {
    if (requestObj) {
      return Object.keys(requestObj)
        .filter((key, index) => requestObj[key] === true && listRequestInfo[index])
        .map((_, index) => listRequestInfo[index]);
    }

    return [];
  }

  handleRequestMoreInfo = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      "token": this.state.token
    };

    const data = {
      requested_items: this.getListRequested(this.claimFormProps?.values)
    }

    this.requestMoreInfoApiId = requestApi(
      header, 
      "POST", 
      "bx_block_custom_forms/claims/request_more_info?claim_id=" + this.claimId,
      data
    );
  }

  handleUpdateStatus = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      "token": this.state.token
    };

    const data = {
      claim_id: this.claimId,
      claim: {
        claim_status: "claim_completed"
      }
    }

    this.updateStatusApiId = requestApi(
      header,
      "PUT",
      "bx_block_custom_forms/claims/update_claim_status",
      data
    );
  }

  handleRequestResponse = async (responseJson: any, status: string) => {
    this.setState({ loading: false });

    if (responseJson.errors && responseJson.errors[0]) {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: responseJson.errors[0],
        snackBarType: "error"
      })
      return;
    }

    this.setState(prevState => ({
      isSnackbarOpen: true,
      snackBarType: "success",
      snackbarMessage: responseJson.message,
      claimData: {
        ...prevState.claimData,
        attributes: {
          ...prevState.claimData.attributes,
          claim_status: status
        }
      }
    }));
  }

  handleExportClaim = () => {
    this.setState({ loading: true });

    const endPoint = baseURL 
    + "/bx_block_custom_forms/claims/export_claim_data?claim_id=" 
    + this.claimId + "&token="
    + this.state.token;

    window.open(endPoint, "_blank");

    this.setState(prevState => ({
      loading: false,
      isSnackbarOpen: true,
      snackBarType: "success",
      snackbarMessage: "Export file successfully!",
      claimData: {
        ...prevState.claimData,
        attributes: {
          ...prevState.claimData.attributes,
          claim_status: "exported_info"
        }
      }
    }));
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiRequestErrorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestErrorMessage !== undefined) {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: apiRequestErrorMessage,
        loading: false,
        snackBarType: "error"
      })
      return;
    }

    if (apiRequestCallId === this.getClaimDetailApiId) {
      this.handleApiResponse(response);
    }

    if (apiRequestCallId === this.getAllClaimCategoriesApiId) {
      this.handleGetAllClaimTypeResponse(response);
    }

    if (apiRequestCallId === this.updateStatusApiId) {
      this.handleRequestResponse(response, "claim_completed");
    }

    if (apiRequestCallId === this.requestMoreInfoApiId) {
      this.handleRequestResponse(response, "requested_more_info");
    }
    // Customizable Area End
  }
}